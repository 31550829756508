import React, { Component } from 'react';
import {storage} from '../firebase';
import toastr from  'toastr';

class FileUpload extends Component {

    styleInputFile = {
        position: 'absolute',
        top: -1000
    }

    constructor(props){
        super(props);
        this.state = {
            url: '',
            fileName:'',
            progress:0,
            refName: '',
            pathName: props.pathName || 'boletos',            
            hash: '',
            loading:false
        }
    }

    uploadChange(e){
        let inputFile = e.target;
        if(inputFile.files.length){
            let file = inputFile.files[0];
            let randomHash = btoa(`${Math.random()}-${Math.random()}-${Math.random()}-${Math.random()}`);
            let refName = `${this.state.pathName}/${randomHash}/${file.name}`;            
            this.setState({refName, loading: true})

            let taskUp = storage
                .ref(refName)
                .put(file);

            taskUp.on('state_changed', eProgress=>{                
                const progress = Math.round((eProgress.bytesTransferred / eProgress.totalBytes) * 100);
                this.setState({progress});

            }, eError=>{
                toastr.error('Erro ao fazer upload', 'FileUpload');
                this.setState({loading: false})
                
            }, eComplete=>{
                storage.ref(this.state.pathName).child(`${randomHash}/${file.name}`).getDownloadURL().then(url => {                    
                    this.setState({url, fileName: file.name, loading: false, hash: randomHash});
                    this.change();
                })                
            });
        }        
    }

    deleteFile(){
        this.setState({loading: true});        
        storage
        .ref(this.state.refName)
        .delete()
        .then(e=>{
            this.change();            
            this.refs.inputFile.value = '';
            this.setState({
                url: '',
                fileName:'',
                progress:0,
                refName: '',
                pathName: this.props.pathName || 'boletos',            
                hash: '',
                loading:false
            });
        })
        .catch(eError=>{
            toastr.error('Erro ao apagar arquivo', 'FileUpload');
            this.setState({loading: false});            
        })        
    }

    change(){
        if(this.props.onChange){
            this.props.onChange({url:this.state.url, fileName:this.state.fileName, hash: this.state.hash});
        }
    }

    clickAnexar(){
        this.refs.inputFile.click();
    }

    getClassAnexo(){
        if(this.state.loading) {            
            return "fa fa-spinner spn-running";            
        }
        else{            
            return "fa fa-paperclip";
        }
    }

    getClassApagar(){       

        if(this.state.loading) {
            return "fa fa-spinner spn-running";            
        }
        else{
            return "fa fa-close";
        }
    }

    cleanning(){
        this.setState({
            url: '',
            fileName:'',
            progress:0,
            refName:'',
            loading:false
        });
    }

    render() {
        return (
            <span hidden={this.props.hidden}>
                <input 
                    type="file" 
                    style={this.styleInputFile} 
                    ref="inputFile" 
                    onChange={e => this.uploadChange(e)} />                
                <button 
                    className="btn btn-info" 
                    ref="btnAnexo" 
                    disabled={this.state.loading ? 'disabled' : ''}
                    onClick={e=> this.clickAnexar()} 
                    hidden={this.state.fileName}>
                    <i className={this.getClassAnexo()}></i> {!this.state.loading ? 'Selecione o arquivo' : 'Enviando...'+this.state.progress+'%'}
                </button>
                <span>
                    <a className="btn btn-info styleLinkFile"
                        title={this.state.fileName}                        
                        href={this.state.url} 
                        hidden={!this.state.fileName}
                        target="_aboutBlank">
                        <i className="fa fa-download"></i> {this.state.fileName}
                    </a>
                </span>
                <button 
                    onClick={e=>this.deleteFile()} 
                    hidden={!this.state.fileName}
                    disabled={this.state.loading ? 'disabled' : ''}
                    className="btn btn-danger" 
                    title="Apagar">
                    <i className={this.getClassApagar()}></i></button>
            </span>
        );
    }
}

export default FileUpload;