
function setUsuario(user){
    if(user){
        localStorage.setItem('user-bill', JSON.stringify(user));
        return;
    }
    localStorage.removeItem('user-bill');
}

function getUsuario(){
    let strJson = localStorage.getItem('user-bill')
    if(strJson){
        let user = JSON.parse(strJson);
        return user;
    }
    return null;        
}

export { getUsuario, setUsuario }