import React, { Component } from 'react';
import {database, storage} from '../firebase';
import FileUpload from '../upload/FileUpload';
import toastr from  'toastr';
import { recaptcha } from '../recaptcha'
import Menu from '../nav-bar/Menu'

class BillCrud extends Component {

    constructor(props){
        super(props);
        this.state = {
            bills: [],
            bill: {
                competencia:'',
                vencimento:'',
                urlBoleto:'',
                fileNameBoleto:'',
                hashBoleto: '',
                urlNotaFiscal:'',
                fileNameNotaFiscal:'',
                hashNotaFiscal: ''
            },
            temSolicitacao: false
        }
    }

    componentDidMount(){        
        this.watchFirebase();
    }

    componentWillUpdate(){
    }

    watchFirebase(){
        database.ref('bills').on('value', source => {            
            let listResult = [];
            for(let key in source.val()){
                listResult.push(source.val()[key]);
            }      
            listResult = listResult.sort((a, b)=>{
                let arData = a.vencimento.split('/');
                let dataVencimentoa = new Date(`${arData[2]}-${arData[1]}-${arData[0]}`);
                arData = b.vencimento.split('/');
                let dataVencimentob = new Date(`${arData[2]}-${arData[1]}-${arData[0]}`);
                return dataVencimentoa-dataVencimentob;
            });
            this.setState({bills: listResult});
        });

        database
            .ref('bills-order')
            .on('value', source=>{                
                if(source.val()){
                    this.setState({temSolicitacao: true})
                }
                else{
                    this.setState({temSolicitacao: false})
                }                
            })
    }

    deleteBill(item){

        let msgAlert = '';

        if(item.urlBoleto){
            msgAlert+='Ainda tem boleto nesta bill';
        }
        
        if(item.urlNotaFiscal){
            msgAlert+='\n\rAinda tem fiscal nesta bill';
        }

        if(msgAlert) {

            toastr.warning(msgAlert, 'operação inválida - bill');
            return;
        }

        database
            .ref('bills/'+item.uid)
            .remove()
            .then(e=>{
                toastr.success('Bill apagado com sucesso!', 'bill');
            })
            .catch(e=>{
                toastr.error('Erro ao deletar Bill', 'bill');
            });
    }
    addBill(){
        
        let msgAlert = '';

        if(!this.state.bill.urlNotaFiscal) {
        
            if(!this.state.bill.vencimento){
                msgAlert+='Informe o vencimento';
            }

            let dataVencimento = new Date(this.state.bill.vencimento);

            if(dataVencimento < new Date()){
                msgAlert+='\n\rInfome um vencimento válido';
            }        
        }

        if(msgAlert){
            toastr.warning(msgAlert, 'bill');
            return;
        }

        let date = new Date();
        let uid = btoa(`${date.toLocaleString()}:${date.getMilliseconds()}-${Math.random()}-${Math.random()}-${Math.random()}-${Math.random()}`);
        let bill = {...this.state.bill, uid};
        let arDate = bill.vencimento.split('-');
        bill.competencia = this.refs.optMes.value+'/'+this.refs.optAno.value;
        bill.vencimento = `${arDate[2]}/${arDate[1]}/${arDate[0]}`;

        database
            .ref('bills/' + uid)
            .set(bill)
            .then(item=>{
                toastr.success('Bill adicionado com sucesso!', 'bill');
                let bill = {
                    competencia:'',
                    vencimento:'',
                    urlBoleto:'',
                    fileNameBoleto:'',
                    hashBoleto: '',
                    urlNotaFiscal:'',
                    fileNameNotaFiscal:'',
                    hashNotaFiscal: ''
                };
                this.refs.vencimento.value = '';
                this.refs.fileBoleto.cleanning();
                this.refs.fileNotaFiscal.cleanning();
                this.setState({bill});
            })
            .catch(eError=>{
                console.log('eError', eError);
                toastr.error('Erro ao adicionar item', 'bill');
            });
    }    

    setUrlBoleto(file, item){

        let newitem = {...item};

        newitem.urlBoleto = file.url;
        newitem.fileNameBoleto = file.fileName;
        newitem.hashBoleto = file.hash;
        database
        .ref('bills/' + newitem.uid)
        .set(newitem)
        .then(e=>{
            toastr.success('Atribuição de url boleto feita com sucesso!', 'bill');
        })
        .catch(eError=>{            
            toastr.error('Erro ao atribuir url boleto!', 'bill');
        });
    }

    removeUrlBoleto(item, fUploadBoleto){

        let refFileNameBoleto = `boletos/${item.hashBoleto}/${item.fileNameBoleto}`;
        let newItem = {...item};

        newItem.urlBoleto = '';
        newItem.fileNameBoleto = '';
        newItem.hashBoleto = '';

        database
        .ref('bills/' + newItem.uid)
        .set(newItem)
        .then(eThen=>{

            fUploadBoleto.cleanning();

            toastr.success('Remoção de url boleto feita com sucesso!', 'bill');            

            storage
            .ref(refFileNameBoleto)
            .delete()
            .then(e=>{
                toastr.success(`Arquivo ${item.fileNameBoleto} boleto apagado com sucesso do storage`, 'bill');
            })
            .catch(eError=>{
                toastr.error(`Erro ao apagar arquivo ${item.fileNameBoleto} boleto`, 'bill')
            }) 
        })
        .catch(eError=>{
            toastr.error('Erro ao remover url do boleto','bill');
        });
    }    

    setUrlNotaFiscal(file, item){

        let newitem = {...item};

        newitem.urlNotaFiscal = file.url;
        newitem.fileNameNotaFiscal = file.fileName;
        newitem.hashNotaFiscal = file.hash;
        database
        .ref('bills/' + newitem.uid)
        .set(newitem)
        .then(e=>{
            toastr.success('Atribuição de url nota fiscal feita com sucesso!', 'bill');
        })        
        .catch(eError=>{
            toastr.error('Erro ao atribuir url nota fiscal!', 'bill');
        });        
    }

    removeUrlNotaFiscal(item, fUploadNota){

        let refFileNameNotaFiscal = `notas/${item.hashNotaFiscal}/${item.fileNameNotaFiscal}`;
        let newItem = {...item};

        newItem.urlNotaFiscal = '';
        newItem.fileNameNotaFiscal = '';
        newItem.hashNotaFiscal = '';

        database
        .ref('bills/' + newItem.uid)
        .set(newItem)
        .then(eThen=>{

            fUploadNota.cleanning();

            toastr.success('Remoção de url nota fiscal feita com sucesso!', 'bill');

            storage
            .ref(refFileNameNotaFiscal)
            .delete()
            .then(e=>{
                toastr.success(`Arquivo ${item.fileNameNotaFiscal} nota fiscal apagado com sucesso do storage`, 'bill');
            })
            .catch(eError=>{
                toastr.error(`Erro ao apagar arquivo ${item.fileNameNotaFiscal} nota fiscal`, 'bill')                
            }) 
        })
        .catch(eError=>{
            toastr.error('Erro ao remover url nota fiscal','bill');
        });        
    }    

    renderItens(){        
        
        return this.state.bills.map((item, index)=>
            
            <tr key={index}>
                <td>{item.competencia}</td>
                <td>{item.vencimento}</td>
                <td>
                    <a className="btn btn-info styleLinkFile"
                        title={item.fileNameBoleto}                        
                        href={item.urlBoleto} 
                        hidden={!item.urlBoleto}
                        target="_aboutBlank">
                        <i className="fa fa-download"></i> {item.fileNameBoleto}
                    </a>
                    <FileUpload ref={'fUploadBoleto'+index} hidden={item.urlBoleto} pathName="boletos" onChange={file => this.setUrlBoleto(file, item) } />
                    <button hidden={!item.urlBoleto} onClick={e=>this.removeUrlBoleto(item, this.refs['fUploadBoleto'+index])}
                        className="btn btn-warning" 
                        title={'Apagar o boleto '+item.fileNameBoleto}>
                        <i className="fa fa-close"></i>
                    </button>                
                </td>
                <td>
                    <a className="btn btn-info styleLinkFile"
                        title={item.fileNameNotaFiscal}
                        style={this.styleLinkFile}
                        href={item.urlNotaFiscal} 
                        hidden={!item.urlNotaFiscal}
                        target="_aboutBlank">
                        <i className="fa fa-download"></i> {item.fileNameNotaFiscal}
                    </a>
                    <FileUpload ref={'fUploadNota'+index} hidden={item.urlNotaFiscal} pathName="notas" onChange={file => this.setUrlNotaFiscal(file, item) } />
                    <button hidden={!item.urlNotaFiscal} onClick={e=>this.removeUrlNotaFiscal(item, this.refs['fUploadNota'+index])}
                        className="btn btn-warning" 
                        title={'Apagar o nota fiscal '+item.fileNameNotaFiscal}>
                        <i className="fa fa-close"></i>
                    </button>
                </td>
                <td>
                    <button onClick={e=>this.deleteBill(item)}                        
                        className="btn btn-danger" 
                        title="Apagar licença">
                        <i className="fa fa-close"></i>
                    </button>
                </td>
            </tr>
        );
    }

    renderAnos(){
        let list = [];
        let date = new Date();
        let ultimoAno = date.getFullYear()-1
        let atualAno = date.getFullYear();

        list.push(date.getFullYear());
        list.push(date.getFullYear()-1);

        return (
            <select className="custom-select" ref="optAno">
                <option value={ultimoAno}>{ultimoAno}</option>
                <option value={atualAno}>{atualAno}</option>
            </select>
        );
    }

    apagarSolicitacao(){
        database
            .ref('bills-order')
            .remove()
            .then(e=>{
                this.setState({temSolicitacao: false})
                toastr.success('Solicitação apagada com sucesso!', 'bill');
            })
            .catch(e=>{
                toastr.error('Erro ao apagar solicitação', 'bill');
            }); 
    }

    render() {
        return (
            <div className="card">
                <div className="card-header alert-primary">
                   <img src="logo_mini.png" alt="Logo - Axiosi"></img>
                   <h5>[Licença-SFG-L6] Cadastro de boletos e notas fiscais</h5>
                </div>
                <div className="card-body"> 
                    <Menu />
                    <div className="table-responsive">
                        <table className="table table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>Competência</th>
                                    <th>Vencimento</th>
                                    <th>Boleto</th>
                                    <th>Nota Fiscal</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="bg-warning">
                                    <td>
                                        <div className="row">
                                            <div className="col-8">
                                                <select ref="optMes" className="custom-select">
                                                    <option value="JANEIRO">JANEIRO</option>
                                                    <option value="FEVEREIRO">FEVEREIRO</option>
                                                    <option value="MARÇO">MARÇO</option>
                                                    <option value="ABRIL">ABRIL</option>
                                                    <option value="MAIO">MAIO</option>
                                                    <option value="JUNHO">JUNHO</option>
                                                    <option value="JULHO">JULHO</option>
                                                    <option value="AGOSTO">AGOSTO</option>
                                                    <option value="SETEMBRO">SETEMBRO</option>
                                                    <option value="OUTUBRO">OUTUBRO</option>
                                                    <option value="NOVEMBRO">NOVEMBRO</option>
                                                    <option value="DEZEMBRO">DEZEMBRO</option>
                                                </select>                                            
                                            </div>
                                            <div className="col-4">
                                                {this.renderAnos()}                                            
                                            </div>                                            
                                        </div>
                                    </td>
                                    <td><input className="form-control" type="date" ref="vencimento" onChange={e=> this.setState({bill: {...this.state.bill, ...{vencimento: e.target.value }}}) } placeholder="Vencimento"/></td>
                                    <td><FileUpload ref="fileBoleto" pathName="boletos" onChange={file => this.setState({bill: {...this.state.bill, ...{urlBoleto: file.url, fileNameBoleto: file.fileName, hashBoleto: file.hash }}}) } /></td>
                                    <td><FileUpload ref="fileNotaFiscal" pathName="notas" onChange={file => this.setState({bill: {...this.state.bill, ...{urlNotaFiscal: file.url, fileNameNotaFiscal: file.fileName, hashNotaFiscal: file.hash }}})} /></td>
                                    <td>
                                        <button onClick={e=>this.addBill()}
                                            className="btn btn-primary" 
                                            title="Adicionar">
                                            <i className="fa fa-plus"></i>
                                        </button>                                
                                    </td>
                                </tr>                        
                                {this.renderItens()}
                            </tbody>                    
                        </table>
                    </div>
                    <button className="btn btn-danger" hidden={!this.state.temSolicitacao} onClick={e=> this.apagarSolicitacao()}><i className="fa fa-close"></i>  Apaguar solicitação de boleto</button>
                </div>
            </div>
        );
    }
}
//################################ >> BillCrud << ################################//


//################################ >> BillList << ################################\\
class BillList extends Component {

    constructor(props){
        super(props);
        this.state = {
            bills: [],
            esconderPedidoBoleto: true,
            esconderFormPedido: true,
            nome:'',
            email:'',
            fone:'',
            hashRecaptcha:null,
            loading: false,
            solicitacaoEmProcessamento: false
        }    
    }
    componentDidMount(){        

        database.ref('bills').on('value', source => {            
            let listResult = [];
            for(let key in source.val()){
                listResult.push(source.val()[key]);
            }      

            listResult = listResult.sort((a, b)=>{
                let arData = a.vencimento.split('/');
                let dataVencimentoa = new Date(`${arData[2]}-${arData[1]}-${arData[0]}`);
                arData = b.vencimento.split('/');
                let dataVencimentob = new Date(`${arData[2]}-${arData[1]}-${arData[0]}`);
                return dataVencimentoa-dataVencimentob;
            });
            let ultimaBill = listResult[listResult.length-1];
            let arData = ultimaBill.vencimento.split('/');
            let vencimento = new Date(`${arData[2]}-${arData[1]}-${arData[0]}`);

            if(vencimento.getTime() < Date.now()){
                this.setState({bills: listResult, esconderPedidoBoleto: false});    
            }
            else{
                this.setState({bills: listResult, esconderPedidoBoleto: true});    
            }            
        });

        recaptcha.addCallBackVerify(hashRecaptcha => {
            this.setState({hashRecaptcha});    
        });

        database
            .ref('bills-order')
            .on('value', source=>{
                if(source.val()){
                    this.setState({solicitacaoEmProcessamento: true, loading: true});
                }else {
                    this.setState({solicitacaoEmProcessamento: false, loading: false});
                }
            });
    }

    atribuirClassTr(item){
        if(item.urlBoleto && item.urlNotaFiscal){
            return 'table-success';
        }
        let arData = item.vencimento.split('/');
        let dataVencimento = new Date(`${arData[2]}-${arData[1]}-${arData[0]}`);
        
        if(dataVencimento < new Date() && !item.urlNotaFiscal){
            return 'table-danger';
        }
         return '';
    }

    renderItens(){        
        return this.state.bills.map((item, index)=>              
            <tr key={index} className={this.atribuirClassTr(item)}>
                <td>{item.competencia}</td>
                <td>{item.vencimento}</td>
                <td>
                    <a className="btn btn-warning styleLinkFile"
                        title={item.fileNameBoleto}                        
                        href={item.urlBoleto} 
                        hidden={!item.urlBoleto}
                        target="_aboutBlank">
                        <i className="fa fa-download"></i> {item.fileNameBoleto}
                    </a>                    
                </td>
                <td>
                    <a className="btn btn-success styleLinkFile"
                        title={item.fileNameNotaFiscal}
                        href={item.urlNotaFiscal} 
                        hidden={!item.urlNotaFiscal}
                        target="_aboutBlank">
                        <i className="fa fa-download"></i> {item.fileNameNotaFiscal}
                    </a>                 
                </td>
            </tr>
        );
    }

    solicitarBoleto(){

        this.setState({loading: true});

        database
            .ref('bills-order')
            .once('value', source=>{
                console.log('source.val()',source.val());
                if(source.val()){
                    toastr.warning('Já existe uma solicitação de 2º via em andamento.');
                    this.setState({loading: false});                    
                }
                else {
                    let now = new Date();

                    let body = { 
                        nome: this.state.nome, 
                        fone: this.state.fone, 
                        email: this.state.email,
                        hashRecaptcha: this.state.hashRecaptcha,
                        uid: btoa(now.toLocaleString()+':'+now.getMilliseconds()+'-'+Math.random()+'-'+Math.random()+'-'+Math.random())
                    };
            
                    database
                        .ref('bills-order/'+body.uid)
                        .set(body)
                        .then(e=>{
                            this.setState({
                                esconderFormPedido: true,
                                nome:'',
                                email:'',
                                fone:'',
                                hashRecaptcha: null,
                                loading: false
                            });
                            this.refs.nome.value = '';
                            this.refs.email.value = '';
                            this.refs.fone.value = '';
                            recaptcha.reset();
                            toastr.success('Novo boleto solicitado com sucesso!!');
                            this.setState({solicitacaoEmProcessamento: true, loading: true});
                        })
                        .catch(eError=>{
                            console.error('Erro ao solicitar novo boleto.', eError);
                            toastr.error('Erro ao solicitar novo boleto.');
                            this.setState({loading: false});
                        });                    
                }
            });
    }

    disabledBtnBoleto(){
        if(this.state.loading) return true;
        if(!this.state.nome) return true;
        if(!this.state.fone) return true;
        if(!this.state.email) return true;
        if(!this.state.hashRecaptcha) return true;
        return false;
    }

    getClassLoad(className){
        if(this.state.loading) {
            return "fa fa-spinner spn-running";            
        }
        else {
            return className;
        }
    }

    getText(textDefault, textLoading){
        return this.state.loading ? textLoading : textDefault;
    }

    render() {
        return (
            <div className="card">
                <div className="card-header alert-primary">
                    <img src="logo_mini.png" alt="Logo - Axiosi"></img>
                    <h5>[Licença-SFG-L6] Lista de boletos e notas fiscais</h5>
                </div>
                <div className="card-body">     
                    <Menu />
                    <div className="table-responsive">           
                        <table className="table table-hover table-striped">
                            <thead>
                                <tr>
                                    <th>Competência</th>
                                    <th>Vencimento</th>
                                    <th>Boleto</th>
                                    <th>Nota Fiscal</th>
                                </tr>
                            </thead>
                            <tbody>                     
                                {this.renderItens()}
                            </tbody>                    
                        </table>
                    </div>                    

                    <div className="container-recaptcha col-md-5" hidden={this.state.esconderPedidoBoleto} >

                        <button className="btn btn-warning" disabled={this.state.solicitacaoEmProcessamento}  hidden={!this.state.esconderFormPedido} onClick={e=> {this.setState({esconderFormPedido:!this.state.esconderFormPedido}); setTimeout(window.scrollTo, 125, 0, 1000000000000); }}><i className={this.getClassLoad('fa fa-barcode')}></i>  {this.getText('Boleto vencido, solicitar um novo', 'Aguarde, boleto em processamento...')}</button>
                        <br/>
                        <br/>
                        <div className="card" hidden={this.state.esconderFormPedido}>                            
                            <div className="card-header bg-warning">
                                <strong>Solicitação de novo boleto</strong>
                            </div>
                            <div className="card-body">
                                <div>
                                    <div className="form-group">
                                        <label htmlFor="nome">Informe seu Nome</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend"><span className="input-group-text"><i className="fa fa-user"></i></span></div>
                                            <input type="text" ref="nome" name="nome" className="form-control" onChange={e=>this.setState({nome:e.target.value})} placeholder="João Fulano Beltrano da Silva" />
                                        </div>
                                    </div>                                    
                                    <div className="form-group">
                                        <label htmlFor="fone">Informe seu telefone</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend"><span className="input-group-text"><i className="fa fa-phone"></i></span></div>
                                            <input type="tel" ref="fone" name="fone" className="form-control" onChange={e=>this.setState({fone:e.target.value})} placeholder="69 99999 1234" />
                                        </div>                                        
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Informe seu email</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend"><span className="input-group-text"><i className="fa fa-envelope"></i></span></div>
                                            <input type="email" ref="email" name="email" className="form-control" onChange={e=>this.setState({email:e.target.value})} placeholder="email@email.com" />
                                        </div>                                                                                                                        
                                    </div>
                                    <div >
                                        <div id="g-recaptcha-boleto" className="g-recaptcha" data-sitekey="6LeqOngUAAAAABaDDmys49HWmpLFzVTXaG2YqbiV"></div>
                                    </div>
                                    <br/>
                                    <button className="btn btn-warning" disabled={this.disabledBtnBoleto()} onClick={e=>this.solicitarBoleto()}><i className={this.getClassLoad("fa fa-bullhorn")} ></i>  {this.getText('Solicitar','Solicitando')}</button>
                                    &nbsp;&nbsp;
                                    <button className="btn btn-danger" onClick={e=>this.setState({esconderFormPedido: !this.state.esconderFormPedido})}><i className="fa fa-close"></i>  Cancelar</button>
                                </div>                                
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>            
        );
    }
}

export { BillList, BillCrud };