import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class Menu extends Component {

    componentDidMount(){
        console.log('componentDidMount', document.location.pathname);
    }

    render() {
        return (
            <div>
                <nav className="">
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <Link className={document.location.pathname === '/' ? 'alert-primary nav-item nav-link active' : 'alert-primary nav-item nav-link'} to="/" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Listas</Link>
                        <Link className={document.location.pathname === '/BillCrud' ? 'alert-primary nav-item nav-link active' : 'alert-primary nav-item nav-link'} to="/BillCrud" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Manager</Link>
                        <Link className={document.location.pathname === '/Login' ? 'alert-primary nav-item nav-link active' : 'alert-primary nav-item nav-link'} to="/Login" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Login</Link>
                    </div>
                </nav>  
                <br/>              
            </div>
        );
    }
}

export default Menu;