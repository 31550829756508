import React, { Component } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import Routes from './Routes';
import 'toastr/build/toastr.min.css';

class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      user:null
    };
  }

  render() {
    return (
      <div className="App container-fluid center">
        <hr/>
        <div className="">
          <Routes />
        </div>
      </div>
    );
  }
}

export default App;
