import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/storage';
//import '@firebase/firestore';
import '@firebase/database';

//import firebaseui from 'firebaseui';

var config = {
  apiKey: "AIzaSyBPV7sNfgtUWCjj9QEXcMs-k3zXYozxLh8",
  authDomain: "pt-sfg.firebaseapp.com",
  databaseURL: "https://pt-sfg.firebaseio.com",
  projectId: "pt-sfg",
  storageBucket: "pt-sfg.appspot.com",
  messagingSenderId: "20705740137"
};

  firebase.initializeApp(config);

  const auth = firebase.auth();
  const storage = firebase.storage();  
  //const firestore = firebase.firestore();
  const database = firebase.database();  

  //export { auth, storage, firestore, firebase as default }
  export { auth, storage, database, firebase as default }